import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import './calendarAdmin.scss';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import "moment-timezone";
import moment from 'moment';
moment.locale('fr')

export default class CalendarAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateDisabled: [{ 'date_arrivee': 0, 'date_depart': 0 }],
      dateToDisableBetween: [],
      disableIfTwoSameDate:[]
    }
  }
  componentDidMount = () => {
    let date = [];
    let dateTab = [];
    let dateToDisableBetween = [];
    const db = firebase.firestore();
    db.collection("list_voyageurs")
      .where("etat_email", "==", "Validé")
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach(doc => {
            date.push({
              date_arrivee: doc.data().date_arrivee,
              date_depart: doc.data().date_depart
            })
          }
          )
          for (let i = 0; i < date.length; i++) {
            dateTab.push(date[i].date_arrivee);
            dateTab.push(date[i].date_depart)
          }
          //tri le tableau de date
          dateTab.sort()
          // si 2 fois la même date, push dans un nouveau tableau
          for (let i = 0; i < dateTab.length; i++) {
            if (dateTab[i] === dateTab[i + 1]) {
              dateToDisableBetween.push(new Date(dateTab[i]))
            }
          }
          //si deux dates identiques alors elle est grisée
          let disableIfTwoSameDate = dateToDisableBetween;
          for (let i = 0; i < date.length; i++) {
            disableIfTwoSameDate.push({ after: new Date(date[i].date_arrivee), before: new Date(date[i].date_depart) })
          }
          this.setState({
            dateDisabled: date,
            dateToDisableBetween: dateToDisableBetween,
            disableIfTwoSameDate: disableIfTwoSameDate
          })
        }
      })
  }

  render() {
    return (
      <div className='calendar-admin'>
        <DayPicker
          numberOfMonths={2}
          localeUtils={MomentLocaleUtils}
          locale='fr'
          disabledDays={this.state.disableIfTwoSameDate}
        />
      </div>
    )
  }
}
