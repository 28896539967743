import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import app from "./Base";
import { AuthContext } from "./Auth";
import { Card, Input, CardText, CardBody, Button, Form, Label } from 'reactstrap';
import './login.scss';
import { Link } from 'react-router-dom';


const Login = ({ history, props }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/admin");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/admin" />;
  }

  return (
    <div className="login">
      <Form onSubmit={handleLogin}>
        <Card body inverse color="info">
          <CardBody>
            <CardText>
              <Label>
                Email
           <Input name="email" type="email" placeholder="Email" />
              </Label>
              <br />
              <Label>
                Mot de passe
           <Input name="password" type="password" placeholder=" Mot de passe" />
              </Label>
              <br />
              <Button type="submit" color="success">Valider</Button>
              <Link to={"/"} className="text-color nav-link">
              <Button color="warning">Retour accueil</Button>
              </Link>
            </CardText>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
};

export default withRouter(Login);
