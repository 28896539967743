import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './AllCards.scss';
import { Card, CardTitle, CardImg, CardImgOverlay } from 'reactstrap';
import { Link } from 'react-router-dom';


class AllCards extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div className="AllCards">
        <Container>
          <Row>
            <Col lg="4" md="4" className="card-hover card-margin">
                <Card inverse>
                <Link to={'/infos'}>
                  <CardImg src="/images/info.png" alt="Card image cap" />
                  <CardImgOverlay>
                    <CardTitle className="text">Infos</CardTitle>
                  </CardImgOverlay>
                  </Link>
                </Card>
            </Col>
            <Col lg="4" md="4" className="card-hover card-margin">
              <Card inverse>
                <Link to={'/galerie'}>
                  <CardImg src="/images/salon.png" alt="Card image cap" />
                  <CardImgOverlay>
                    <CardTitle className="text">Galerie</CardTitle>
                  </CardImgOverlay>
                </Link>
              </Card>
            </Col>
            <Col lg="4" md="4" className="card-hover card-margin">
              <Card inverse>
                <Link to={'/reservation'}>
                  <CardImg src="/images/reservation.png" alt="Card image cap" />
                  <CardImgOverlay>
                    <CardTitle className="text">Je réserve</CardTitle>
                  </CardImgOverlay>
                </Link>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AllCards;