import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import axios from 'axios'
import "./client.scss";
import { Link } from 'react-router-dom';
import { SEND_EMAIL } from '../../constants/';
import { Container, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import 'moment/locale/fr';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import "moment-timezone";
import moment from 'moment';
moment.locale('fr');

export default class Client extends Component {
  static defaultProps = {
    numberOfMonths: 2,
  };
  constructor(props) {
    super(props);
    this.state = {
      nom: '',
      prenom: '',
      telephone: '',
      email: '',
      date_arrivee: '',
      date_depart: '',
      commentaire: '',
      commentaire_admin: '',
      from: '',
      to: '',
      modifuserstate: false,
      isLoading: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
    this.modifUser = this.modifUser.bind(this);
    this.sendMailConfirmation = this.sendMailConfirmation.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  componentDidMount() {
    firebase.firestore()
      .collection("list_voyageurs")
      .doc(this.props.match.params.id)
      .get()
      .then(doc => {
        this.setState({
          nom: doc.data().nom,
          prenom: doc.data().prenom,
          telephone: doc.data().telephone,
          email: doc.data().email,
          date_arrivee: doc.data().date_arrivee,
          date_depart: doc.data().date_depart,
          commentaire: doc.data().commentaire,
          date_arrivee_bdd: '',
          date_depart_bdd: '',
        })
      })
  }

  valide = () => {
    if (this.state.from !== undefined && this.state.to !== undefined) {
      this.setState({
        date_arrivee_bdd: moment(this.state.from).format('YYYY-MM-DD'),
        date_depart_bdd: moment(this.state.to).format('YYYY-MM-DD')
      })
    } else {
      this.setState({
        date_arrivee_bdd: moment(this.state.date_arrivee).format('YYYY-MM-DD'),
        date_depart_bdd: moment(this.state.date_depart).format('YYYY-MM-DD')
      })
    }
  }

  modifUserSansEmail = async () => {
    await this.valide()
    firebase.firestore()
      .collection("list_voyageurs")
      .doc(this.props.match.params.id)
      .update({
        nom: this.state.nom,
        prenom: this.state.prenom,
        telephone: this.state.telephone,
        email: this.state.email,
        date_arrivee: this.state.date_arrivee_bdd,
        date_depart: this.state.date_depart_bdd,
        commentaire: this.state.commentaire,
        etat_email: 'Validé',
      })
      .then(() => {
        alert("La confirmation a bien été prise en compte")
        document.location.reload()
      })
      .catch(() => { alert("Une erreure est survenue") })
  }

  async modifUser() {
    await this.valide()
    firebase.firestore()
      .collection("list_voyageurs")
      .doc(this.props.match.params.id)
      .update({
        nom: this.state.nom,
        prenom: this.state.prenom,
        telephone: this.state.telephone,
        email: this.state.email,
        date_arrivee: this.state.date_arrivee_bdd,
        date_depart: this.state.date_depart_bdd,
        commentaire: this.state.commentaire,
        etat_email: 'Validé',
      })
      .then(() => {
        console.log('ok')
      })
      .catch(() => {
        console.log('err')
      })
  }

  sendMailFunction = async () => {
    this.setState({
      isLoading: true
    })
    await axios.get(SEND_EMAIL, {
      params: {
        nom: this.state.nom,
        prenom: this.state.prenom,
        telephone: this.state.telephone,
        email: this.state.email,
        date_arrivee: moment(`${this.state.date_arrivee_bdd}`).format('LL'),
        date_depart: moment(`${this.state.date_depart_bdd}`).format('LL'),
        commentaire: this.state.commentaire,
        commentaire_admin: this.state.commentaire_admin,
      }
    })
      .then(() => {
        this.setState({
          isLoading: false
        })
        alert("La confirmation a bien été envoyée")
        setTimeout(() => {
          document.location.reload()
        }, 200)
      }).catch((err) => {
        this.setState({
          isLoading: false
        })
        alert(err)
        setTimeout(() => {
          document.location.reload()
        }, 200);
      })
  }


  sendMailConfirmation(e) {
    e.preventDefault();
    this.modifUser()
    this.sendMailFunction()
  }


  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  render() {
    const { nom, prenom, telephone, email, date_arrivee, date_depart, commentaire, from, to, commentaire_admin } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <div className='client'>
        <Container className="form">
          <Form className="form">
            <Row>
              <Col lg="3">
                <FormGroup>
                  <Label for="nom">Nom</Label>
                  <Input
                    type="lastname"
                    onChange={this.handleChange}
                    name="nom"
                    id="nom"
                    placeholder="Nom"
                    required
                    value={nom || ''}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <Label for="prenom">Prénom</Label>
                  <Input
                    type="firstname"
                    onChange={this.handleChange}
                    name="prenom"
                    id="firstname"
                    placeholder="Prénom"
                    required
                    value={prenom || ''}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <Label for="tel">Téléphone</Label>
                  <Input
                    type="text"
                    onChange={this.handleChange}
                    name="telephone"
                    id="tel"
                    placeholder="Téléphone"
                    required
                    value={telephone || ''}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <Label for="email" className="mr-sm-2">Email</Label>
                  <Input
                    type="email"
                    onChange={this.handleChange}
                    name="email"
                    id="email"
                    placeholder="exemple@email.com"
                    required
                    value={email || ''}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
            </Row>
            <FormGroup>
              <p>Dates demandées : du {moment(date_arrivee).format('LL')} au {moment(date_depart).format('LL')}</p>
              &nbsp;
              <Button type="button" className="btn btn-info btn-sm" data-toggle="modal" data-target="#myModal">Sélectionnez les nouvelles dates</Button>
              &nbsp;
                {date_arrivee &&
                to &&
                `Nouvelles dates : du ${moment(from).format('LL')} au
                ${moment(to).format('LL')}`}{' '}
              &nbsp;
              <br />
              {from && to && (
                <Button type="button" className="btn btn-warning btn-sm link" onClick={this.handleResetClick}>
                  Effacer les nouvelles dates
                </Button>
              )}
              <div id="myModal" data-backdrop="false" className="modal fade cal" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="RangeExample">
                        <p>
                          {!from && !to && 'Sélectionnez votre jour d\' arrivée.'}
                          {from && !to && 'Sélectionner votre jour de départ'}

                        </p>
                        <DayPicker
                          className="Selectable"
                          numberOfMonths={this.props.numberOfMonths}
                          selectedDays={[from, { from, to }]}
                          modifiers={modifiers}
                          onDayClick={this.handleDayClick}
                          localeUtils={MomentLocaleUtils}
                          locale='fr'
                        />
                      </div>

                    </div>
                    <div className="modal-footer">
                      <Button type="button" className="btn btn-info" data-dismiss="modal">Valider</Button>
                    </div>
                  </div>

                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="comentaire">Commentaire</Label>
              <Input
                type="textarea"
                onChange={this.handleChange}
                name="commentaire"
                id="commentaire"
                required
                value={commentaire || ''}
              />
            </FormGroup>
            <Link to={`/admin`}>
              <Button type="button" className="link btn-danger" >Retour</Button>
            </Link>
            <Button type="button" className="btn btn-warning" onClick={this.modifUserSansEmail}>Modifier sans envoyer de mail</Button>
            <Button type="button" className="link btn-success" data-toggle="modal" data-target="#exampleModal" onClick={this.valide}>Valider et envoyer le mail</Button>
          </Form>
        </Container>
        <Form>
          <FormGroup>
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <Label for="commentaire_admin">Ajouter un commentaire de confirmation</Label>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Input
                      type="textarea"
                      onChange={this.handleChange}
                      name="commentaire_admin"
                      placeholder='Bonjour...'
                      id="commentaire_admin"
                      required
                      value={commentaire_admin}
                    />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Retour</button>
                    {this.state.isLoading ?
                      <button class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                     Envoi du mail en cours
                  </button> :
                      <button type="button" className="btn btn-primary" onClick={this.sendMailConfirmation}>Confirmer et envoyer le mail</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </FormGroup>
        </Form>
      </div>
    )
  }
}
