import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';import './Reservation.scss'
import { Button, Form, FormGroup, Label, Input, Container, Row, Col } from 'reactstrap';
import Header from '../Header';
import { EMAIL_PROD } from '../../constants/';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import "moment-timezone";
import moment from 'moment';
moment.locale('fr');
class Reservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom: "",
      prenom: "",
      telephone: "",
      email: "",
      date_arrivee: "",
      date_depart: "",
      commentaire: "",
      isOpen: false,
      from: '',
      to: '',
      goMail: '',
      dateDisabled: [{ 'date_arrivee': 0, 'date_depart': 0 }],
      selectedDay: undefined,
      dateToDisableBetween: [],
      disableIfTwoSameDate: []
    }
    this.handleChange = this.handleChange.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }

  componentDidMount = () => {
    let date = [];
    let dateTab = [];
    let dateToDisableBetween = [];
    const db = firebase.firestore();
    db.collection("list_voyageurs")
      .where("etat_email", "==", "Validé")
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach(doc => {
            date.push({
              date_arrivee: doc.data().date_arrivee,
              date_depart: doc.data().date_depart
            })
          }
          )
          for (let i = 0; i < date.length; i++) {
            dateTab.push(date[i].date_arrivee);
            dateTab.push(date[i].date_depart)
          }
          //tri le tableau de date
          dateTab.sort()
          // si 2 fois la même date, push dans un nouveau tableau
          for (let i = 0; i < dateTab.length; i++) {
            if (dateTab[i] === dateTab[i + 1]) {
              dateToDisableBetween.push(new Date(dateTab[i]))
            }
          }
          //si deux dates identiques alors elle est grisée
          let disableIfTwoSameDate = dateToDisableBetween;
          for (let i = 0; i < date.length; i++) {
            disableIfTwoSameDate.push({ after: new Date(date[i].date_arrivee), before: new Date(date[i].date_depart) })
          }
          this.setState({
            dateDisabled: date,
            dateToDisableBetween: dateToDisableBetween,
            disableIfTwoSameDate: disableIfTwoSameDate
          })
        }
      })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }


  envoiBdd() {
    if (this.state.from !== undefined && this.state.to !== undefined) {
      this.setState({
        goMail: true,
      })
      const db = firebase.firestore();
      db.collection("list_voyageurs").add({
        nom: this.state.nom,
        prenom: this.state.prenom,
        telephone: this.state.telephone,
        email: this.state.email,
        date_arrivee: this.state.from.toISOString().slice(0, 10),
        date_depart: this.state.to.toISOString().slice(0, 10),
        commentaire: this.state.commentaire,
        etat_email: 'En attente',
        arrhes: 'En attente'
      })
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    } else {
      alert('Merci de choisir une date')
      this.setState({
        goMail: false,
      })
      document.location.reload()
    }
  }


  sendMail(e) {
    e.preventDefault();
    this.envoiBdd();
    const db = firebase.firestore();
    db.collection("mail").add({
      to: this.state.email,
      message: {
        subject: "Nouveau message de EricBess.fr",
        text: this.state.commentaire,
        html: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        <style>
        body{
          background:#fff;
          text-align: center;
          padding: 1%;
        }
        </style>
        
        </head>
        <body style="width:100% !important; -webkit-text-size-adjust:100%; -ms-text-size-adjust:100%; margin:0; padding:0;">
        <p style="color:#000"><h3>Bonjour ${this.state.prenom} ${this.state.nom}, </h3></p>
        <h4 style="color:#000">J'ai bien reçu votre message et reviens vers vous le plus vite possible !<br>
        <br>
        À très bientôt<br>
        Cordialement<br>
        <br>
        <b><i>Eric Bessonnet</i></b><br></h4>
        <br>
        <h5 style="color:#000">Votre demande de réservation:</h5>
    <h5 style="color:#000"><i>Vos informations : <br>
        ${this.state.prenom} ${this.state.nom}<br>
        ${this.state.telephone}<br>
        ${this.state.email}<br>
      date d'arrivée: ${moment(this.state.from).format('LL')}<br>
      date de départ: ${moment(this.state.to).format('LL')}<br>
      commentaire: ${this.state.commentaire}
      </i></h5>
          </body>
        </html>   
    `,
      },
    }).then((docRef) => {
      db.collection("mail").add({
        to: EMAIL_PROD,
        replyTo: this.state.email,
        message: {
          subject: "Nouvelle demande de réservation sur EricBess.fr",
          text: this.state.commentaire,
          html: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
          <html xmlns="http://www.w3.org/1999/xhtml">
          <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          
          <style>
          body{
            background:#fff;
            text-align: center;
            padding: 1%;
          }
          </style>
          
          </head>
          <body style="width:100% !important; -webkit-text-size-adjust:100%; -ms-text-size-adjust:100%; margin:0; padding:0;">
          <p style="color:#000"><h3 style="color:#000">Bonjour ${this.state.prenom} ${this.state.nom}, </h3></p>
          <h4 style="color:#000">J'ai bien reçu votre message et reviens vers vous le plus vite possible !<br>
          <br>
          À très bientôt<br>
          Cordialement<br>
          <br>
          <b><i>Eric Bessonnet</i></b><br></h4>
          <br>
          <h5 style="color:#000">Votre demande de réservation:</h5>
      <h5 style="color:#000"><i>Vos informations : <br>
          ${this.state.prenom} ${this.state.nom}<br>
          ${this.state.telephone}<br>
          ${this.state.email}<br>
        date d'arrivée: ${moment(this.state.from).format('LL')}<br>
        date de départ: ${moment(this.state.to).format('LL')}<br>
        commentaire: ${this.state.commentaire}
        </i></h5>
            </body>
          </html>   
      `,
        },
      }).then(() => {
        this.setState({
          nom: "",
          prenom: "",
          telephone: "",
          email: "",
          date_arrivee: "",
          date_depart: "",
          commentaire: "",
          from: '',
          to: '',
        })
        setTimeout(() => {
          document.location.reload()
        }, 3000);
        alert("Votre demande a bien été envoyée")
      })
    })
      .catch(() => {
        this.setState({
          nom: "",
          prenom: "",
          telephone: "",
          email: "",
          date_arrivee: "",
          date_depart: "",
          commentaire: "",
          from: '',
          to: '',
        })
        setTimeout(() => {
          document.location.reload()
          alert("Il y a eu un problème lors de l'envoi")
        }, 3000);
      })

  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }

  handleDayClick(day, modifiers) {
    if (modifiers.disabled) {
      return;
    }
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }



  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div>
        <Header />
        <Container className="Reservation">
          <h1 className="title-reservation">Je fais une demande de réservation :</h1>
          <div className='form-css'>
            <Form onSubmit={this.sendMail}>
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <Label for="nom">Nom</Label>
                    <Input
                      type="lastname"
                      onChange={this.handleChange}
                      name="nom"
                      id="nom"
                      placeholder="Nom"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Label for="prenom">Prénom</Label>
                    <Input
                      type="firstname"
                      onChange={this.handleChange}
                      name="prenom"
                      id="firstname"
                      placeholder="Prénom"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Label for="tel">Téléphone</Label>
                    <Input
                      type="text"
                      onChange={this.handleChange}
                      name="telephone"
                      id="tel"
                      placeholder="Téléphone"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Label for="email" className="mr-sm-2">Email</Label>
                    <Input
                      type="email"
                      onChange={this.handleChange}
                      name="email"
                      id="email"
                      placeholder="exemple@email.com"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
              </Row>
              <FormGroup>
                <button type="button" className="btn btn-info btn-sm" data-toggle="modal" data-target="#myModal">Sélectionnez vos dates</button>
                &nbsp;
                {from &&
                  to &&
                  `Du ${from.toLocaleDateString()} au
                ${to.toLocaleDateString()}`}{' '}
                &nbsp;
                {from && to && (
                  <button type="button" className="btn btn-warning btn-sm link" onClick={this.handleResetClick}>
                    Modifier les dates
                  </button>
                )}
                <div id="myModal" data-backdrop="false" className="modal fade" role="dialog">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="RangeExample">
                          <p>
                            {!from && !to && 'Sélectionnez votre période de séjour.'}
                            {from && !to && 'Sélectionnez votre période de séjour'}
                          </p>
                          <p className='indispo'>
                            ATTENTION LES DATES GRISÉES SONT INDISPONIBLES
                          </p>
                          <DayPicker
                            className="Selectable"
                            numberOfMonths={2}
                            selectedDays={[from, { from, to }, this.state.selectedDay]}
                            modifiers={modifiers}
                            onDayClick={this.handleDayClick}
                            localeUtils={MomentLocaleUtils}
                            locale='fr'
                            disabledDays={this.state.disableIfTwoSameDate}
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-info" data-dismiss="modal">Valider</button>
                      </div>
                    </div>

                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="comentaire">Commentaire</Label>
                <Input
                  type="textarea"
                  onChange={this.handleChange}
                  name="commentaire"
                  id="commentaire"
                  required
                />
              </FormGroup>
              <span className="arrhes">
                * Versement de 25% d'arrhes après confirmation de la réservation et encaissées à la remise des clés.
                </span>
                <br />
                <br />
              <Button type="submit" className="mb-3 btn-success">Envoyer</Button>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}

export default Reservation;