import React, { Component } from 'react';
import Header from '../Header';
import { Container } from 'reactstrap';
import './Gallery.scss';


class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div>
        <Header />
        <Container className='carousel'>
          <div className='display_carousel'>
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img className="d-block w-100 size_carousel" src="images/galerie/1.png" alt="First slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100 size_carousel" src="images/galerie/2.png" alt="Second slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100 size_carousel" src="images/galerie/3.png" alt="Third slide" />
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100 size_carousel" src="images/galerie/4.png" alt="Third slide" />
                </div><div className="carousel-item">
                  <img className="d-block w-100 size_carousel" src="images/galerie/5.png" alt="Third slide" />
                </div><div className="carousel-item">
                  <img className="d-block w-100 size_carousel" src="images/galerie/6.png" alt="Third slide" />
                </div><div className="carousel-item">
                  <img className="d-block w-100 size_carousel" src="images/galerie/7.png" alt="Third slide" />
                </div><div className="carousel-item">
                  <img className="d-block w-100 size_carousel" src="images/galerie/10.png" alt="Third slide" />
                </div><div className="carousel-item">
                  <img className="d-block w-100 size_carousel" src="images/galerie/11.png" alt="Third slide" />
                </div><div className="carousel-item">
                  <img className="d-block w-100 size_carousel" src="images/galerie/12.png" alt="Third slide" />
                </div>
              </div>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
              </a>
            </div>
          </div>
          <div className='display_galerie'>
            <div className="top">
              <ul>
                <li><a href="#img_1"><img src="/images/galerie/1.png" alt="photo_appart" /></a></li>
                <li><a href="#img_2"><img src="/images/galerie/2.png" alt="photo_appart" /></a></li>
                <li><a href="#img_3"><img src="/images/galerie/3.png" alt="photo_appart" /></a></li>
                <li><a href="#img_4"><img src="/images/galerie/4.png" alt="photo_appart" /></a></li>
                <li><a href="#img_5"><img src="/images/galerie/5.png" alt="photo_appart" /></a></li>
                <li><a href="#img_6"><img src="/images/galerie/6.png" alt="photo_appart" /></a></li>
                <li><a href="#img_7"><img src="/images/galerie/7.png" alt="photo_appart" /></a></li>
                <li><a href="#img_10"><img src="/images/galerie/10.png" alt="photo_appart" /></a></li>
                <li><a href="#img_11"><img src="/images/galerie/11.png" alt="photo_appart" /></a></li>
                <li><a href="#img_12"><img src="/images/galerie/12.png" alt="photo_appart" /></a></li>
              </ul>
              <a href="#_1" className="lightbox trans" id="img_1"><img src="/images/galerie/1.png" alt="photo_appart" /></a>
              <a href="#_2" className="lightbox trans" id="img_2"><img src="/images/galerie/2.png" alt="photo_appart" /></a>
              <a href="#_2" className="lightbox trans" id="img_3"><img src="/images/galerie/3.png" alt="photo_appart" /></a>
              <a href="#_3" className="lightbox trans" id="img_4"><img src="/images/galerie/4.png" alt="photo_appart" /></a>
              <a href="#_5" className="lightbox trans" id="img_5"><img src="/images/galerie/5.png" alt="photo_appart" /></a>
              <a href="#_6" className="lightbox trans" id="img_6"><img src="/images/galerie/6.png" alt="photo_appart" /></a>
              <a href="#_7" className="lightbox trans" id="img_7"><img src="/images/galerie/7.png" alt="photo_appart" /></a>
              <a href="#_8" className="lightbox trans" id="img_10"><img src="/images/galerie/10.png" alt="photo_appart" /></a>
              <a href="#_8" className="lightbox trans" id="img_11"><img src="/images/galerie/11.png" alt="photo_appart" /></a>
              <a href="#_8" className="lightbox trans" id="img_12"><img src="/images/galerie/12.png" alt="photo_appart" /></a>
            </div>
          </div>
        </Container>

      </div >
    );
  }
}

export default Gallery;