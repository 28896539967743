import React, { Component } from 'react';
import app from '../Authentification/Base';
import './admin.scss';
import { Button } from 'reactstrap';
import Liste from './Liste';
import CalendarAdmin from './CalendarAdmin';

export default class Admin extends Component {
  render() {
    return (
      <div className="admin">
        <h1>Bonjour !</h1>
        <Button color="danger" onClick={() => app.auth().signOut()}>Déconnexion</Button>
        <Liste />
        <CalendarAdmin />
      </div>
    )
  }
}

