import React, { Component } from 'react';
import './Header.scss';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infos:'text-color nav-link',
      galerie:'text-color nav-link',
      reservation:'text-color nav-link'
    }
  }

  componentDidMount = () => {
    console.log(window.location.href.includes('infos'))
    let link = window.location.href
    if (link.includes('infos')){
      this.setState({
        infos: 'text-color nav-link infos'
      })
    }
    if (link.includes('galerie')){
      this.setState({
        galerie: 'text-color nav-link galerie'
      })
    }
    if (link.includes('reservation')){
      this.setState({
        reservation: 'text-color nav-link reservation'
      })
    }
  }


  render() {
    return (
      <div className="Header">
        <Container>
          <div className="navbar-nav">
            <Row>
              <Col>
                <Link to={"/"} className='text-color nav-link'>
                  Accueil
                      </Link>
              </Col>
              <Col>
                <Link to={"/infos"} className={this.state.infos}>
                  Infos
                      </Link>
              </Col>
              <Col>
                <Link to={"/galerie"} className={this.state.galerie}>
                  Galerie
                      </Link>
              </Col>
              <Col>
                <Link to={"/reservation"} className={this.state.reservation}>
                  Je réserve
                      </Link>
              </Col>
            </Row>
          </div>
        </Container>
        
      </div>
    );
  }
}

export default Header;