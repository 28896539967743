export const URL_EMAIL = `${process.env.REACT_APP_URL_EMAIL}`;
export const URL_POST_BDD = `${process.env.REACT_APP_URL_POST_BDD}`;
export const URL_GET_BDD = `${process.env.REACT_APP_URL_GET_BDD}`;
export const URL_GET_USER = `${process.env.REACT_APP_URL_GET_USER}`;
export const URL_MODIF_VOYAGEUR = `${process.env.REACT_APP_URL_MODIF_VOYAGEUR}`;
export const URL_SUPPRESSION_USER = `${process.env.REACT_APP_URL_SUPPRESSION_USER}`;
export const URL_EMAIL_CONFIRMATION = `${process.env.REACT_APP_URL_EMAIL_CONFIRMATION}`;
export const URL_GET_DATE = `${process.env.REACT_APP_URL_GET_DATE}`;
export const URL_MODIF_ARRHES = `${process.env.REACT_APP_URL_MODIF_ARRHES}`;
export const EMAIL_PROD = `${process.env.REACT_APP_EMAIL_PROD}`;
export const SEND_EMAIL = `${process.env.REACT_APP_SEND_EMAIL}`;
export const USER_MDP = `${process.env.USER_MDP}`;
export const MAIL_TRANSPORT = `${process.env.MAIL_TRANSPORT}`;
