import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import Infos from './components/DiffCards/Infos'
import Gallery from './components/DiffCards/Gallery';
import Reservation from './components/DiffCards/Reservation';
import Login from './components/Authentification/Login';
import {AuthProvider} from './components/Authentification/Auth';
import PrivateRoute from './components/Authentification/PrivateRoute';
import Admin from './components/admininistration/Admin';
import Client from './components/admininistration/Client';
import NotFoundPage from './components/NotFoundPage';



class App extends Component {
  render() {
    return (
      <div className="App">
      <AuthProvider>
        <Switch>
          <Route exact path="/" component={Layout} />
          <Route path="/infos" component={Infos} />
          <Route path="/galerie" component={Gallery} />
          <Route path="/reservation" component={Reservation} />
          <PrivateRoute path="/admin" component={Admin} />
          <PrivateRoute path="/client/:id" component={Client} />
          <Route path="/login" component={Login} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </AuthProvider>
      </div>

    );
  }
}

export default App;
