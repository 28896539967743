import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Link } from 'react-router-dom';
import './liste.scss';
import { Table, Container, Label } from 'reactstrap';
import "moment-timezone";
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr')



export default class Liste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      passId: '',
      modif_arrhes: '',
      passArrhes: '',
    }
    this.supprimer = this.supprimer.bind(this)
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const clientsData = []
    firebase.firestore().collection("list_voyageurs")
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach(doc => {
            clientsData.push({data : doc.data(), id: doc.id})
          })
          this.setState({
            clients: clientsData
          })
        }
      })
  }

  supprimer(e) {
    e.preventDefault();
    const id = this.state.passId
    firebase.firestore()
      .collection('list_voyageurs')
      .doc(id)
      .delete().then(() => document.location.reload())
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  arrhes(arrhesValue, id) {
    firebase.firestore().collection("list_voyageurs")
      .doc(id)
      .update({
        arrhes: arrhesValue
      }).then(() => {
        alert('Modification bien prise en compte')
        document.location.reload()
      })        
  }


  render() {
    return (
      <Container>
        <div className='liste'>
          <Table dark bordered hover responsive size="sm">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Téléphone</th>
                <th>E-mail</th>
                <th>Date d'arrivée</th>
                <th>Date de départ</th>
                <th>Commentaire</th>
                <th>Confirmation</th>
                <th>Arrhes</th>
                <th>Voir</th>
                <th>Supprimer</th>
              </tr>
            </thead>
            <tbody>
              {this.state.clients.map((client ,index) => (
                <tr key={index}>
                  <td>{client.data.nom}</td>
                  <td>{client.data.prenom}</td>
                  <td>{client.data.telephone}</td>
                  <td>{client.data.email}</td>
                  <td>
                    {moment(client.data.date_arrivee).format('LL')}
                  </td>
                  <td>{moment(client.data.date_depart).format('LL')}
                  </td>
                  <td>{client.data.commentaire}</td>
                  <td>{client.data.etat_email}</td>
                  <td>
                    <button type="button" onClick={() => { this.setState({ passArrhes: client.data.arrhes, passId: client.id }) }} className="btn btn-warning" data-toggle="modal" data-target="#arrhes">
                      Arrhes
                    </button>
                    <div className="modal fade text_modal" id="arrhes" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Validation des Arrhes </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <Label for="recipient-name" className="col-form-label">{this.state.passArrhes}</Label>
                            <input
                              type="text"
                              className="form-control"
                              id={client.id}
                              name="modif_arrhes"
                              value={this.state.modif_arrhes}
                              onChange={this.handleChange}
                              placeholder='Mise à jour des arrhes...'
                            ></input>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-info" data-dismiss="modal">Retour</button>
                            <button type="button" className="btn btn-success" onClick={() => this.arrhes(this.state.modif_arrhes, this.state.passId)}>Valider</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Link to={`/client/${client.id}`}>
                      <button type="button" className="btn btn-info" onClick={this.tab}>Voir</button>
                    </Link>
                  </td>
                  <td>
                    <button type="button" onClick={() => { this.setState({ passId: client.id }) }} className="btn btn-danger" data-toggle="modal" data-target="#supp">
                      Supprimer
                    </button>
                    <div className="modal fade text_modal" id="supp" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Suppression réservation de {client.data.prenom} {client.data.nom}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            Attention la suppression est irréversible, êtes-vous sûr ?
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-info" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger" onClick={this.supprimer}>Supprimer</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )
              )}
            </tbody>
          </Table>
        </div>
      </Container>
    );
  }
}


